/* body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
  } */
  
  .card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 400px;
    padding: 20px;
    text-align: center;
  }
  
  .tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .tab {
    flex: 1;
    padding: 10px;
    cursor: pointer;
    background-color: #f0f0f0;
    border: none;
    font-size: 16px;
    outline: none;
    transition: background-color 0.3s ease;
  }
  
  .tab:hover {
    background-color: #ddd;
  }
  
  .active {
    background-color: #3498db;
    color: #fff;
  }
  
  .tab-content {
    font-size: 18px;
    padding: 20px;
    /* background-color: #f9f9f9; */
    border-radius: 10px;
  }
  