.date-picker {
    width: 100%;
    height: 45px;
    font-size: 14px;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #e2e8f0;
  }
  
  .date-picker-wrapper {
    display: block;
    width: 100%;
  }