.full-width-container {
    background-color: white; /* Removed the grey color */
    /* border-radius: 15px; */
    padding: 3rem;
    margin: 2rem auto;
    max-width: 800px; /* Centered, full-width card */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); Soft shadow */
  }
  
  .form-title {
    font-size: 32px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    color: #33334d;
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .form-label {
    font-size: 14px;
    font-weight: 600;
    color: #333;
  }
  
  .form-control {
    font-size: 14px;
    padding: 0.75rem 1rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    width: 100%;
    transition: border-color 0.2s ease;
  }
  
  .form-control:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .col-12, .col-lg-6 {
    flex: 1;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }
  
  .btn {
    padding: 0.75rem 1.5rem;
    font-size: 16px;
    font-weight: bold;
    border-radius: 0.375rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-create {
    background-color: #117554;
  }
  
  .btn-create:hover {
    background-color: #0d5e40;
  }
  
  .btn-clear {
    background-color: #b8001f;
  }
  
  .btn-clear:hover {
    background-color: #99001a;
  }
  